import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "priceMsrp", "priceSale" ]

  increase() {
    event.preventDefault()
    if (this.outputTarget.value) {
      this.numberCeil(Number(this.outputTarget.value) + 1)
    }
  }

  decrease() {
    event.preventDefault()
    if (this.outputTarget.value) {
      this.numberCeil(Number(this.outputTarget.value) - 1)
    }
  }

  setMsrpPrice() {
    this.numberCeil(Number(this.priceMsrpTarget.dataset.price))
  }

  setSalePrice() {
    this.numberCeil(Number(this.priceSaleTarget.dataset.price))
  }

  numberCeil(num) {
    this.outputTarget.value = Math.ceil(num)
  }
}