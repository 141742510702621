import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.getFreeDiskSpace()
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content
  }

  getFreeDiskSpace() {
    event.preventDefault()

    fetch(this.data.get("fetch-url"), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      }
    }).then(response => response.json())
    .then(result => this.outputTarget.innerHTML = result)
  }
}
