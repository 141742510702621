import { Controller } from "stimulus"

export default class extends Controller {
  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content
  }

  // If true - set all to checked, uncheck all if false
  checkOrUncheckAll(boolean) {
    let checkboxes = document.querySelectorAll("input[type='checkbox'][name='offer_ids[]']")
    for (let checkbox of checkboxes) {
      checkbox.checked = boolean
    }
  }

  checkedCheckboxes() {
    return document.querySelectorAll("input[type='checkbox'][name='offer_ids[]']:checked")
  }

  selectAll(event) {
    event.preventDefault()
    this.checkOrUncheckAll(true)
  }

  deselectAll(event) {
    event.preventDefault()
    this.checkOrUncheckAll(false)
  }

  deleteSelected(event) {
    event.preventDefault()
    let checkboxesNodes = this.checkedCheckboxes()
    let checkboxes = []

    for (let checkboxNode of checkboxesNodes) {
      checkboxes.push(checkboxNode.value) // get offer id from checkbox value
    }

    fetch(this.data.get("destroy-url"), {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      },
      body: JSON.stringify({ offer_ids: checkboxes })
    })
    .then(response => response.json())
    .then(offerIDs => {
      for (let id of offerIDs) {
        let offerNode = document.querySelector(`#offer${id}`)
        setTimeout(() => {
          offerNode.style.display = "none"
          offerNode.remove()
        }, 100)
      }
    }).catch(err => err) // do nothing here, rescue from record not found
  }
}
