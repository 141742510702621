import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["productId"];

  setProductIds() {
    // Reset product_ids hidden input value
    let update_prices_ids = document.querySelector(
      "#update_prices_product_ids"
    );
    let destroy_product_stocks_ids = document.querySelector(
      "#destroy_product_stocks_ids"
    );
    let destroy_products_ids = document.querySelector(
      "#destroy_products_product_ids"
    );
    if (update_prices_ids) update_prices_ids.value = null;
    if (destroy_product_stocks_ids) destroy_product_stocks_ids.value = null;
    if (destroy_products_ids) destroy_products_ids.value = null;

    let prod_ids_arr = [];
    this.productIdTargets.forEach((el) => {
      if (el.checked) prod_ids_arr.push(el.value);
    });

    let prod_ids_str = prod_ids_arr.join(",");
    // Set new value to product_ids
    if (update_prices_ids) update_prices_ids.value = prod_ids_str;
    if (destroy_product_stocks_ids)
      destroy_product_stocks_ids.value = prod_ids_str;
    if (destroy_products_ids) destroy_products_ids.value = prod_ids_str;
  }
}
