import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
import "controllers"

import "css/bootstrap"
import "swiper/swiper-bundle.css"

import "bootstrap"
import flatpickr from "flatpickr"
import Swiper from "swiper/bundle"
import SmartPhoto from "smartphoto"

Rails.start()
Turbolinks.start()

// Images with <%= image_pack_tag 'rails.png' %>
require.context("../images", true)

document.addEventListener("turbolinks:load", function() {
  let linkFaktoryEl = document.querySelector("#link-faktory")
  if (linkFaktoryEl) {
    linkFaktoryEl.href = `https://faktory.${window.location.host}`
  }

  let linkLPMEl = document.querySelector("#link-lpm")
  if (linkLPMEl) {
    linkLPMEl.href = `https://lpm.${window.location.host}`
  }

  new SmartPhoto(".spotlight", {
    nav: false
  })

  // Offers#new date-picker
  flatpickr("#offer_date", {
    altInput: true,
    altFormat: "m-d-y",
    dateFormat: "Y-m-d"
  })

  // Offers#show image slides
  new Swiper(".swiper-container", {
    slidesPerView: 3,
    spaceBetween: 10,
    watchSlidesVisibility: true,
    preloadImages: false,
    lazy: true
  });

  new Swiper('.swiper-b2b-logos', {
    slidesPerView: 20,
    spaceBetween: 10,
    centeredSlides: true,
    centeredSlidesBounds: true,
    watchSlidesVisibility: true,
    preloadImages: false,
    loop: true,
    lazy: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    }
  });

  new Swiper('.swiper-container-b2b', {
    cssMode: true,
    slidesPerView: 10,
    spaceBetween: 20
  });
})
